<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.887 12.887" class="svg-spyglass">
        <path class="a" d="M13.831,12.594l-3.34-3.34A5.26,5.26,0,1,0,9.254,10.49l3.34,3.34ZM2.142,6.221A4.079,4.079,0,1,1,6.221,10.3,4.079,4.079,0,0,1,2.142,6.221Z" transform="translate(-0.944 -0.944)"/>
    </svg>

</template>

<script>
    export default {
        mounted() {
        }
    }
</script>
