<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.79 1.631" class="svg-minus">
        <g class="cls-1" transform="translate(0 0)">
            <rect class="cls-2" width="9.789" height="1.632" rx="0.816"/>
            <rect class="cls-3" x="0.5" y="0.5" width="8.789" height="0.632" rx="0.316"/>
        </g>
    </svg>
</template>

<script>
    export default {
        mounted() {
        }
    }
</script>
