<template>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9.661 9.661" class="svg-plus">
        <path class="cls-1" d="M52.856,47.636h-3.22v3.22a.805.805,0,0,1-1.61,0v-3.22h-3.22a.805.805,0,0,1,0-1.61h3.22v-3.22a.805.805,0,0,1,1.61,0v3.22h3.22a.805.805,0,0,1,0,1.61Z" transform="translate(-44 -42)"/>
    </svg>

</template>

<script>
    export default {
        mounted() {
        }
    }
</script>
