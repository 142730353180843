<template>
    <div>
        <form method="post"
              :action="modal.action"
              @submit.prevent="validateForm()"
              :name="'entreprisedemand'"
              :id="'form-store-entreprisedemand'"
              :class="type">
            <input type="hidden" name="_token" v-model="csrf"/>
            <input type="hidden" name="entreprise_id" v-model="modal.fields.entreprise_id"/>
            <input v-if="modal.fields.etablissement_id" type="hidden" name="etablissement_id" v-model="modal.fields.etablissement_id"/>
            <input type="hidden" name="demand_type" v-model="type"/>
            <div class="form-group row error" style="display:none" v-show="error">
                <div class="col-12 bg-danger text-white mt-4 p-3">
                    <p>Le formulaire comporte quelques erreurs signalées en rouge, merci de les corriger avant de ressoumettre celui-ci</p>
                </div>
            </div>
            <div class="form-group row errorSubmit" style="display:none" v-show="error && submitResults">
                <div class="col-12 bg-danger text-white mt-4 p-3">
                    <p>Nous ne pouvons pas traiter votre demande.</p>
                    <p>Merci de réessayer plus tard.</p>
                    <div v-if="error.errorV">
                        <p><strong>Les erreurs rencontrées lors de la validation sont :</strong></p>
                        <ul class="list-unstyled ml-4">
                            <li v-for="(errorMsg, indexErrorMsg) in error.errorV" :key="indexErrorMsg"><i class="mdi mdi-chevron-right zmdi-hc-lg"></i> {{ errorMsg[0] }}</li>
                        </ul>
                    </div>
                    <div class="p-3 alert-danger" v-if="error.errorS">
                        <p><code>{{ error.errorS }}</code></p>
                    </div>
                </div>
            </div>
            <div class="form-group row checkbox-alternate" v-show="type!=='update'">
                <b-form-group>
                    <b-form-radio-group
                        v-model="modal.fields.type_of_actor"
                        :options="actor_types"
                        name="radio_type_of_actor"
                    ></b-form-radio-group>
                </b-form-group>
            </div>
            <div class="form-group row">
                <label for="input-denomination" class="col-12 col-md-4 col-form-label">Nom de l'entreprise /<br>de l'établissement <sup class="text-danger">*</sup></label>
                <div class="col-12 col-md-8">
                    <input type="text"
                           name="denomination"
                           class="form-control"
                           id="input-denomination"
                           v-model="modal.fields.denomination"
                           v-validate="{ required:true }"
                           data-vv-as="*nom de l'entreprise*"/>
                    <small v-show="errors.has('denomination')" class="form-text text-danger">{{ errors.first('denomination') }}</small>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-bce" class="col-12 col-md-4 col-form-label">N° d'entreprise<br> du siège social <sup class="text-danger">*</sup></label>
                <div class="col-12 col-md-8">
                    <input type="text"
                           name="bce"
                           class="form-control"
                           id="input-bce"
                           placeholder="ex.: 123456789"
                           data-vv-as="*bce*"
                           v-model="modal.fields.bce"
                           v-validate="{ required:true, bce: true }"
                           :readonly="type=='update' ? true : false">
                    <small v-if="type=='update'" class="form-text d-inline-block text-danger">Cette information est extraite de la BCE. Pour toute modification, rendez-vous sur <a href="http://www.myenterprise.be/my_enterprise/" class="text-danger" target="_blank">myentreprise.be</a></small>
                    <span v-else class="form-text text-muted small">ex.: 123456789 ou 0123456789</span>
                    <small v-show="errors.has('bce')" class="form-text text-danger">{{ errors.first('bce') }}</small>
                    <p class="form-text small text-danger mb-0" v-if="checkBceResult">Ce BCE existe déjà dans notre base de donnée ?<br />Modifier votre <a :href="modal.routeBce +'/bce/'+modal.fields.bce">fiche</a> ? </p>
                    <small class="form-text small valid-feedback" v-if="entrepriseResult">{{ entrepriseResult.denomination }}</small>
                    <p class="form-text small text-danger mb-0" v-if="etablissementParentNotExist">Ce BCE n'existe pas dans notre base de donnée.</p>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-address" class="col-12 col-md-4 col-form-label">Rue et n°<sup class="text-danger">*</sup></label>
                <div class="col-12 col-md-8">
                    <input type="text"
                           name="address"
                           class="form-control"
                           id="input-address"
                           v-model="modal.fields.address"
                           v-validate="{ required:true }"
                           data-vv-as="*adresse*"
                           :readonly="type=='update' ? true : false">
                    <small v-if="type=='update'" class="form-text d-inline-block text-danger">Cette information est extraite de la BCE. Pour toute modification, rendez-vous sur <a href="http://www.myenterprise.be/my_enterprise/" class="text-danger" target="_blank">myentreprise.be</a></small>
                    <small v-show="errors.has('address')" class="form-text text-danger">{{ errors.first('address') }}</small>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-zip" class="col-12 col-md-4 col-form-label">Code postal<sup class="text-danger">*</sup></label>
                <div class="col-12 col-md-2">
                    <input type="text"
                           name="zip"
                           class="form-control"
                           id="input-zip"
                           maxlength="7"
                           v-model="modal.fields.zip"
                           v-validate="{ required:true, max:7 }"
                           data-vv-as="*code postal*">
                </div>
                <label for="input-city" class="col-12 col-md-1 col-form-label">Ville<sup class="text-danger">*</sup></label>
                <div class="col-12 col-md-5">
                    <input type="text"
                           name="city"
                           class="form-control"
                           id="input-city"
                           v-model="modal.fields.city"
                           v-validate="{ required:true }"
                           data-vv-as="*ville*">
                </div>
                <div class="col-12 col-md-8 offset-md-4">
                    <small v-show="errors.has('zip')" class="form-text text-danger">{{ errors.first('zip') }}</small>
                    <small v-show="errors.has('city')" class="form-text text-danger">{{ errors.first('city') }}</small>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-phone" class="col-12 col-md-4 col-form-label">Téléphone</label>
                <div class="col-12 col-md-8">
                    <input type="tel"
                           name="phone"
                           class="form-control"
                           id="input-phone"
                           v-model="modal.fields.phone"
                           v-validate="{ required:true, sanitize_phone:true, max:11 }"
                           data-vv-as="*téléphone*">
                    <span class="text-muted small">ex.: 021238956</span>
                    <small v-show="errors.has('phone')" class="form-text text-danger">{{ errors.first('phone') }}</small>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-email" class="col-12 col-md-4 col-form-label">Email</label>
                <div class="col-12 col-md-8">
                    <input type="email"
                           name="email"
                           class="form-control"
                           id="input-email"
                           v-model="modal.fields.email"
                           v-validate="{ email:true }"
                           data-vv-as="email">
                    <span class="text-muted small">ex.: robert.durant@gmail.com</span>
                    <small v-show="errors.has('email')" class="form-text text-danger">{{ errors.first('email') }}</small>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-website" class="col-12 col-md-4 col-form-label">Site Web</label>
                <div class="col-12 col-md-8">
                    <input type="text"
                           name="website"
                           class="form-control"
                           id="input-website"
                           v-model="modal.fields.website"
                           v-validate="{ url:{require_protocol: true } }"
                           data-vv-as="*site web*">
                    <span class="text-muted small">ex.: <strong>http://</strong>www.votresite.com</span>
                    <small v-show="errors.has('website')" class="form-text text-danger">{{ errors.first('website') }}</small>
                </div>
            </div>
            <div class="form-group row mt-5">
                <label for="input-description" class="col-12 col-md-4 col-form-label pt-0">Description</label>
                <div class="col-12 col-md-8">
                    <textarea name="description"
                              class="form-control"
                              id="input-description"
                              rows="7"
                              maxlength="255"
                              v-model="modal.fields.description"
                              data-vv-as="*description*">{{ modal.fields.description }}</textarea>
                    <span class="text-muted small"><span>{{ modal.fields.description}}</span> / 255 caractères autorisés</span>
                    <small v-show="errors.has('description')" class="form-text text-danger">{{ errors.first('description') }}</small>
                </div>
            </div>
            <!-- <div class="form-group row mt-5 checkbox-alternate types">
                <label class="col-12 col-md-4 col-form-label pt-0">Il s'agit d'un<sup class="text-danger">*</sup></label>
                <div class="col-12 col-md-4">
                    <div class="form-group row">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox"
                                   class="custom-control-input"
                                   id="type-siege-soc"
                                   name="types[]"
                                   value="siege_soc"
                                   v-model="modal.fields.type"
                                   v-validate="{ required:true }"
                                   data-vv-as="*type*"
                                   :checked="type=='update' && modal.fields.type!=null && modal.fields.type.includes('siege_soc')">
                            <label class="custom-control-label" for="type-siege-soc">Siège social</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox"
                                   class="custom-control-input"
                                   id="type-pdv"
                                   name="types[]"
                                   value="pdv"
                                   v-model="modal.fields.type"
                                   v-validate="{ required:true }"
                                   :checked="type=='update' && modal.fields.type!=null && modal.fields.type.includes('pdv')">
                            <label class="custom-control-label" for="type-pdv">Point de vente</label>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-4">
                    <div class="form-group row">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox"
                                   class="custom-control-input"
                                   id="type-pdp"
                                   name="types[]"
                                   value="pdp"
                                   v-model="modal.fields.type"
                                   v-validate="{ required:true }"
                                   :checked="type=='update' && modal.fields.type!=null && modal.fields.type.includes('pdp')">
                            <label class="custom-control-label" for="type-pdp">Point de production</label>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox"
                                   class="custom-control-input"
                                   id="type-pdd"
                                   name="types[]"
                                   value="pdd"
                                   v-model="modal.fields.type"
                                   v-validate="{ required:true }"
                                   :checked="type=='update' && modal.fields.type!=null && modal.fields.type.includes('pdd')">
                            <label class="custom-control-label" for="type-pdd">Point de dépôt</label>
                        </div>
                    </div>
                </div>
                <small v-show="errors.has('type')" class="form-text text-danger">{{ errors.first('type') }}</small>
            </div> -->
            <!-- CATEGORIES -- >
            <div class="form-group row mt-4 checkbox-alternate cat">
                <label class="col-12 col-md-4 col-form-label mt-1">Catégorie(s) d'activité<sup class="text-danger">*</sup></label>
                <div class="col-12 col-md-8">
                    <div id="accordion" role="tablist" class="row">
                        <b-card no-body class="col-12" v-for="(category, indexCategory) in categories" :key="indexCategory">
                            <b-card-header header-tag="header"
                                           role="tab"
                                           class="row align-items-center justify-content-between px-0 pointer"
                                           :class="{ 'is-valid' : selectedCategories.includes(category.id) || (type=='update' && modal.fields.entrepriseCategories!=null && modal.fields.entrepriseCategories.includes(category.id))}"
                                           :id="'cat'+category.id"
                                           v-b-toggle="'category-'+indexCategory">
                                {{ category.name }}
                                <span class="when-opened align-items-center justify-content-center"><svg-minus></svg-minus></span>
                                <span class="when-closed align-items-center justify-content-center"><svg-plus></svg-plus></span>
                            </b-card-header>
                            <b-collapse :id="'category-'+indexCategory" accordion="my-accordion" role="tabpanel" class="row">
                                <b-card-body class="col-12">
                                    <div class="row">
                                        <div class="col-12 custom-control custom-checkbox" v-for="(subcategory, indexSubCategory) in category.subcategories" :key="indexSubCategory">
                                            <input type="checkbox"
                                                   class="custom-control-input"
                                                   name="categories[]'"
                                                   :id="'actor-subcat-'+subcategory.id"
                                                   :data-category="category.id"
                                                   :value="subcategory.id"
                                                   v-model="modal.fields.entrepriseSubcategories"
                                                   v-validate="{ required:true }"
                                                   @click="categoriesCheckbox"
                                                   :checked="type=='update' && (modal.fields.entrepriseSubcategories!=null && modal.fields.entrepriseSubcategories.includes(subcategory.id))">
                                            <label class="custom-control-label" :for="'actor-subcat-'+subcategory.id">{{ subcategory.name }}</label>
                                        </div>
                                    </div>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
            </div>
            -->

            <!-- TAGS -->
            <div class="form-group row mt-5">
                <div class="col-12 col-md-4 col-form-label pt-0">
                    <div class="row">
                        <label for="input-tags" class="col-12">Mot-clés</label>
                    </div>
                </div>
                <div class="col-12 col-md-8 px-0 tags-wrapper retrieve-tags-wrapper">
                    <transition name="fade">
                        <div class="selected-tags">
                            <p class="tag mb-1" v-for="(tag,index) in currentlyRetrievedTags" :key="index">{{ tag.name }}<a href="javascript:void(0)" @click="removeTag($event, tag.id)" class="ml-4 fa fa-close hvr-grow"></a></p>
                        </div>
                    </transition>
                </div>
            </div>
            <div class="form-group row mt-5">
                <div class="col-12 col-md-4 col-form-label pt-0">
                    <div class="row">
                        <label for="input-tags" class="col-12">Ajouter un mot-clé</label>
                    </div>
                </div>
                <div class="col-12 col-md-8 px-0 tags-wrapper retrieve-tags-wrapper">
                    <div class="input-group">
                        <input class="form-control search-by-tags"
                               name="tag"
                               id="input-tags"
                               v-model="retrieveTagCurrentSearch"
                               :placeholder="'Entrez un mot-clé pour lancer la recherche'"/>
                        <span class="input-group-append">
                            <div><span class="input-group-text"><svg-spyglass></svg-spyglass></span></div>
                        </span>
                        <small class="form-text text-muted">Si aucun résultat ne correspond à votre recherche, merci d'entrer le mot-clé souhaité dans le champ « Créer un mot-clé » ci-dessous</small>
                        <div class="tags-selection">
                            <div class="close-wrapper">
                                <a href="javascript:void(0);" role="button" class="fa fa-close font-weight-light" @click="closeTagSearch"></a>
                            </div>
                            <div>
                                <p v-for="(tag,index) in tagCurrentSearch" :key="index"><a href="javascript:void(0)" @click="selectedTags(tag.id, tag.name, 'tag', true)">{{ tag.name }}</a></p>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" name="tags" v-model="currentlyRetrievedTagsId" />
                </div>
            </div>

            <!-- CREATE TAGS -->
            <div class="form-group row mt-5">
                <div class="col-12 col-md-4 col-form-label pt-0">
                    <div class="row">
                        <label for="input-tags" class="col-12">Créer un mot-clé</label>
                    </div>
                </div>
                <div class="col-12 col-md-8 px-0 tags-wrapper">
                    <div class="form-group input-group mb-1">
                        <input class="form-control" name="addtag" id="input-add-tag" v-model="addTag" placeholder="Créer un mot-clé" />
                        <span class="input-group-append">
                            <div><span class="input-group-text"><a role="button" class="pointer" data-toggle="tooltip" data-placement="bottom" title="Ajouter le mot-clé" @click="addTagToList"><svg-plus></svg-plus></a></span></div>
                        </span>
                    </div>
                    <i class="form-text text-muted small text-right pr-3">Validez ce mot-clé en cliquant ici <i class="mdi mdi-arrow-up"></i></i>
                    <div class="form-group" v-if="addTagList != null">
                        <p class="form-text small mb-0 text-muted">Ces mot-clés seront créés :</p>
                        <ul class="list-unstyled small text-muted">
                            <li v-for="(addedTag,indexAddTag) in addTagList" :key="indexAddTag"><i class="mdi mdi-chevron-right"></i> {{ addedTag }}</li>
                        </ul>
                    </div>
                    <input type="hidden" name="tags_to_create" v-model="addTagList" />
                </div>
            </div>


            <div class="form-group row mt-5">
                <div class="col-12">
                    <h3>Personne de contact</h3>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-contact_name" class="col-12 col-md-4 col-form-label">Votre nom<sup class="text-danger">*</sup></label>
                <div class="col-12 col-md-8">
                    <input type="text"
                           name="contact_name"
                           class="form-control"
                           id="input-contact_name"
                           v-model="modal.fields.contact_name"
                           v-validate="{ required:true }"
                           data-vv-as="*nom de la personne de contact*">
                    <small v-show="errors.has('contact_name')" class="form-text text-danger">{{ errors.first('contact_name') }}</small>
                </div>
            </div>
            <div class="form-group row">
                <label for="input-contact_email" class="col-12 col-md-4 col-form-label">Email<sup class="text-danger">*</sup></label>
                <div class="col-12 col-md-8">
                    <input type="email"
                           name="contact_email"
                           class="form-control"
                           id="input-contact_email"
                           v-model="modal.fields.contact_email"
                           v-validate="{ required:true, email:true }"
                           data-vv-as="*email de la personne de contact*">
                    <small v-show="errors.has('contact_email')" class="form-text text-danger">{{ errors.first('contact_email') }}</small>
                </div>
            </div>
            <div class="form-group row mt-5">
                <label for="input-comment" class="col-12 col-md-4 col-form-label pt-0">Remarque</label>
                <div class="col-12 col-md-8">
                    <textarea name="comment"
                              class="form-control"
                              id="input-comment"
                              rows="7"
                              data-vv-as="*remarque*">{{ modal.fields.comment }}</textarea>
                    <small v-show="errors.has('comment')" class="form-text text-danger">{{ errors.first('comment') }}</small>
                </div>
            </div>
            <div class="form-group row mt-5">
                <div class="custom-control custom-checkbox policy-wrapper">
                    <input type="checkbox"
                           class="custom-control-input"
                           id="policy"
                           name="optin"
                           value="1"
                           v-validate="{ required:true }">
                    <label class="custom-control-label" for="policy">J'accepte la <a href="https://economiesociale.be/politique-de-confidentialite" target="_blank">Politique de Confidentialité</a></label>
                </div>
                <div class="col-12">
                    <small v-show="errors.has('optin')" class="form-text text-danger ml-4">{{ errors.first('fields.optin') }}</small>
                </div>
            </div>

            <div class="form-group row">
                <div class="col-12">
                    <button type="submit" class="btn btn-block" @click.prevent="validateForm()" v-if="submitOk"><span>Envoyer</span></button>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    export default {
        props: [
            'type',
            'csrf',
            'categories',
            'tags'
        ],
        data() {
            return {
                submitOk : true,
                tagClicked : false,
                tagCurrentSearch: [],
                retrieveTagCurrentSearch: '',
                currentlyRetrievedTags : [],
                currentlyRetrievedTagsId : '',

                addTagList : null,
                addTag : '',
                addTagClicked : false,

                modal: this.$parent.modal,
                selectedCategories : [],
                error : false,
                submitResults : false,
                checkBceResult : false,
                onLoad : true,

                description : this.$parent.modal.fields.description,

                accentMap: {
                    'á': 'a',
                    'é': 'e',
                    'í': 'i',
                    'ó': 'o',
                    'ú': 'u',
                    'ê': 'e',
                    'â': 'a',
                    'ô': 'o'
                },
                // type_of_actor: 'siege-social',
                actor_types: [
                    { text: 'Siège social', value: 'siege-social' },
                    { text: 'Établissement', value: 'etablissement' },
                ],
                entrepriseResult: false,
                etablissementParentNotExist: false,
            }
        },
        computed: {
            bce: function(){
                return this.modal.fields.bce;
            },
            type_of_actor : function(){
                return this.modal.fields.type_of_actor;
            },
            etablissement_id: function() {
                if (this.modal.fields.etablissement_id) {
                    return this.modal.fields.etablissement_id
                }
                return false;
            }
        },
        watch: {
            bce : function(newV, oldV){
                if(newV.length >=9 && newV.length <= 10) {
                    this.checkBce(newV);
                }
            },

            submitResults : function(newV, oldV){
                let foo = document.getElementById('form-store-entreprisedemand');
                if(!newV.errorS && !newV.errorV){
                    this.$emit('success', newV);
                }else{
                    $(foo).find('.errorSubmit').show();
                    if(newV.errorS) this.error=newV;
                    else this.error=newV;
                    this.$emit('scroll', this.modal);
                }
            },

            checkBceResult : function(newV, oldV){
                if(newV != false){
                    this.$validator.flag('bce', {
                        valid: false,
                        untouched: false,
                        touched:true,
                        invalid: true,
                        changed:true,
                        dirty: true,
                        validated:true,
                        pristine:false,
                        pending:false
                    });
                }
            },

            

            retrieveTagCurrentSearch(newValue, oldValue) {
                if (newValue.length > 3 && this.tagClicked == false) {
                    this.searchByTagsResults(newValue);
                } else {
                    if (newValue.length <= 3) {
                        $('.retrieve-tags-wrapper .input-group .tags-selection').removeClass('open');

                    }
                }
            },

            type_of_actor : function(newV, oldV){
                if (this.type != 'update') {

                    this.checkBceResult = false;
                    this.entrepriseResult = false;
                    this.etablissementParentNotExist = false;
                    this.modal.fields.bce = '';
                }
               
            },

            // // If new value is true
            etablissementParentNotExist: function(newV, oldV) {
                const field = this.$validator.fields.find({ name: 'bce' });
                if(newV != false){
                    this.$validator.flag('bce', {
                        valid: false,
                        untouched: false,
                        touched:true,
                        invalid: true,
                        changed:true,
                        dirty: true,
                        validated:true,
                        pristine:false,
                        pending:false
                    });
                }
            },

        },
        mounted() {
            // console.log(this.description);
            /*
            if(this.type == 'update' && this.submitResults == false){
                if(this.modal.fields.entrepriseCategories!= null){
                    this.selectedCategories = this.modal.fields.entrepriseCategories;
                }
                setTimeout(()=>{
                    this.$validator.validateAll().then((result) => {
                        if (!result) {
                            $("form").find('.error').fadeIn(500);
                            if(this.selectedCategories.length == 0){
                                let foo = document.getElementById('form-store-entreprisedemand');
                                $(foo).find('.card-header').addClass('is-invalid');
                            }
                            this.error=true;
                            this.$emit('emittoroot', this.test);
                        } else {
                            $("form").find('.error').hide();
                        }
                    });
                },1000);
            }
            */

            /**
             * Rebuild selected Tag from entreprise's tags (from DB sent from Map)
             */
            this.tags.forEach((tag) => {
                if (this.modal.fields.entrepriseTags.includes(tag.id)) {
                    this.currentlyRetrievedTags.push(tag);
                    if (this.currentlyRetrievedTagsId == '') {
                        this.currentlyRetrievedTagsId = tag.id;
                    } else {
                        this.currentlyRetrievedTagsId = this.currentlyRetrievedTagsId + ',' + tag.id;
                    }
                }
            });
            if(this.currentlyRetrievedTagsId != ''){
                $('.retrieve-tags-wrapper .selected-tags').addClass('open');
            }
        },
        
        methods: {
            clearTagSearch: function () {
                $('#search-by-tags-wrapper .input-group .tags-selection').removeClass('open');
                this.inputTagCurrentSearch = '';
                this.tagCurrentSearch = [];
                this.tagClicked = false;
                setTimeout(() => {
                    $("#search-by-tags-wrapper .input-group .form-control").attr('readonly', false);
                    $("#search-by-tags-wrapper .input-group .input-group-append").removeClass('reset');
                    if (refresh) this.refreshMap();
                }, 200);
            },

            selectedTags: function (id, name, type) {
                if (id != 0) {
                    $('.retrieve-tags-wrapper .selected-tags').addClass('open');
                    $('.retrieve-tags-wrapper .input-group').removeClass('open');
                    $('.retrieve-tags-wrapper .input-group .tags-selection').removeClass('open');
                    this.retrieveTagCurrentSearch = '';
                    this.tags.forEach((tag) => {
                        if (tag.id == id) {
                            this.currentlyRetrievedTags.push(tag);
                            if (this.currentlyRetrievedTagsId == '') {
                                this.currentlyRetrievedTagsId = tag.id;
                            } else {
                                this.currentlyRetrievedTagsId = this.currentlyRetrievedTagsId + ',' + tag.id;
                            }
                        }
                    })
                    //console.info(this.currentlyRetrievedTagsId);
                }
            },

            removeTag: function(elem, id) {
                $(elem.target).parents('.tag').fadeOut(500, () => {
                    this.currentlyRetrievedTags.filter((item, index) => {
                        if (item.id === id) {
                            this.currentlyRetrievedTags.splice(index, 1);
                        }
                    });
                    if (this.currentlyRetrievedTags.length > 0) {
                        this.currentlyRetrievedTags.forEach((tag) => {
                            this.currentlyRetrievedTagsId = this.currentlyRetrievedTagsId + ',' + tag.id;
                        });
                    } else {
                        this.currentlyRetrievedTags.forEach((tag) => {
                            this.currentlyRetrievedTagsId = tag.id;
                        });
                    }
                });
            },

            closeTagSearch : function() {
                $('.retrieve-tags-wrapper .input-group .tags-selection').removeClass('open');
            },

            searchByTagsResults: function (searched) {

                let width = $('.retrieve-tags-wrapper .form-control').outerWidth();
                $('.retrieve-tags-wrapper .input-group .tags-selection').width(width + 8);

                let tagsResults = [];
                this.tags.filter((item, index) => {
                    var tempName = this.accent_fold(item.name.toLowerCase());
                    if (tempName.includes(this.accent_fold(searched.toLowerCase()))) {
                        if (!tagsResults.includes(item)) tagsResults.push(item);
                    }
                });
                if (tagsResults.length > 0) {
                    if (tagsResults.length > 0) this.tagCurrentSearch = tagsResults;
                } else {
                    this.tagCurrentSearch = [{
                        'id': '0',
                        'code': '0',
                        'name': 'Pas de résultat pour votre recherche'
                    }];
                }

                $('.retrieve-tags-wrapper .input-group .tags-selection').addClass('open');
                $('.retrieve-tags-wrapper .input-group').addClass('open');
            },

            addTagToList: function(e){
                if(this.addTagList == null) this.addTagList = [];
                this.addTagList.push(this.addTag);

                this.addTag = '';
            },

            checkBce: function(bce){

                if (this.modal.fields.type_of_actor == 'etablissement') {
                    axios.post('/ajax/getEntreprise', {bce: bce})
                        .then(response => {
                            // console.log(response.data.entreprise);
                            this.checkBceResult = false;
                            // const field = this.$validator.fields.find({ name: 'bce' });
                            // field.setFlags({
                            //     invalid: true,
                            // })
                            // console.log(field);
                            if (response.data.entreprise) {
                                this.entrepriseResult = response.data.entreprise;
                                this.etablissementParentNotExist = false;

                            } else {
                                this.entrepriseResult = false;
                                this.etablissementParentNotExist = true;
                            }
                            
                        })
                        .catch(error => {
                            console.warn(error);
                        })
                } else {
                    axios.post('/ajax/checkBce', {bce : bce})
                        .then(response => (this.checkBceResult = response.data.exist))
                        .catch(error => {});
                }
            },

            sendToRoot: function () {
                // this.$emit('emittoroot', this.test);
            },

            validateForm: function(e){
                this.submitOk = false;
                let foo = document.getElementById('form-store-entreprisedemand');
                this.onLoad = false;
                this.$validator.validateAll().then((result) => {
                    if (!result) {
                        $(foo).find('.error').show();
                        if(this.selectedCategories.length == 0){
                            $(foo).find('.card-header').addClass('is-invalid');
                        }
                        this.error=true;
                        this.submitOk = true;
                        this.$emit('scroll', this.test);
                    } else {
                        $(foo).find('.error').hide();
                        if(this.selectedCategories.length > 0){
                            $(foo).find('.card-header').removeClass('is-invalid');
                        }

                        // Send data to PHP
                        let inputs = $(foo).serialize();
                        axios.post('/ajax/demand', inputs)
                            .then(response => (this.submitResults = response.data))
                            .catch((error) => {
                                this.submitResults = error.response.data;
                                this.submitOk = true;
                            });
                    }
                });
            },

            categoriesCheckbox: function(e){
                let elem = e.target;
                let cat = elem.dataset.category;
                if(elem.checked){
                    this.selectedCategories.push(parseInt(cat));
                    if(this.selectedCategories.length > 0){
                        let foo = document.getElementById('form-store-entreprisedemand');
                        $(foo).find('.card-header').removeClass('is-invalid');
                    }
                }else{
                    let index = this.selectedCategories.indexOf(parseInt(cat));
                    if (index > -1) {
                        this.selectedCategories.splice(index, 1);
                        if(this.selectedCategories.length == 0){
                            let foo = document.getElementById('form-store-entreprisedemand');
                            $(foo).find('.card-header').removeClass('is-valid').removeClass('is-invalid');
                        }
                    }
                }
            },

            accent_fold: function (string) {
                if (!string) return '';
                var ret = '';
                for (var i = 0; i < string.length; i++) {
                    ret += this.accentMap[string.charAt(i)] || string.charAt(i);
                }
                return ret;
            }
        }
    }
</script>
