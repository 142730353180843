
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import 'jquery-ui/ui/widgets/slider.js';
require('bootstrap-select');

/**
 * jQuery
 */




/**
 * Vue
 */
window.Vue = require('vue');

import VueRouter from 'vue-router'
Vue.use(VueRouter)


import BootstrapVue from 'bootstrap-vue'
Vue.use(BootstrapVue);

/**
 * Axios
 */
window.axios = require('axios');

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
import VueAxios from 'vue-axios';
import axios from 'axios';
Vue.use(VueAxios, axios);

/**
 * VeeValidate
 */
import fr from 'vee-validate/dist/locale/fr';
const dictionary = {
    fr: {
        messages: {
            /*
            required : () => 'Un petit oubli ?',
            alpha : () => 'Que des lettres ;)',
            email: () => 'ah ! il n\'y aurait pas une petite erreur dans votre email?',
            */
            url: () => 'La valeur entrée n\'est pas correcte, format attendu : "http://www.votresite.com"',
            email: () => 'La valeur entrée n\'est pas correcte, format attendu : "roger@votresite.com"',
            required: ()=> 'Ce champs est obligatoire',
            regex: ()=> ''
        }
    }
};
import VeeValidate, { Validator} from 'vee-validate';
// Validator.localize('fr', dictionary.fr);

Vue.use(VeeValidate, {
    // i18n,
    dictionary: {
        fr
    },
    classes: true,
    classNames: {
        valid: 'is-valid', // model is valid
        invalid: 'is-invalid', // model is invalid
    },
    locale : 'fr',
    validity:false,
    // events: 'blur',
    mode: 'eager'
});
VeeValidate.Validator.extend('sanitize_phone', {
// +32 2 390 00 21
// +32 471 90 42 95
// +32 67 87 44 80
//     getMessage: field => `Le format doit être : +32 2 123 45 67 | +32 471 12 34 56 | +32 81 12 34 56`,
    getMessage: field => `Le format doit être : 021234567 ou 081326598`,
    validate: value => {
        // const regex = /^([+][0-9]{2}\s[0-9]{1,3}\s[0-9]{2,3}\s[0-9]{2}\s[0-9]{2})$/;
        const regex = /^([0]{1}[0-9]{8,12})$/;
        return  regex.test(value);
    }
});

VeeValidate.Validator.extend('bce', {
    getMessage: field => `Le format doit être : 123456789 ou 0123456789`,
    validate: value => {
        const regex = /^([0]?[0-9]{9})$/;
        return  regex.test(value);
    }
});


import VueAnalytics from 'vue-analytics';

Vue.use(VueAnalytics, {
    id : 'UA-127513174-1',
    VueRouter,
    debug: {
        enabled: false, // default value
        trace: true, // default value
        sendHitTask: true // default value
    }
});



/**
 * Leaflet
 */
window.L = require('leaflet/dist/leaflet');
require('leaflet.markercluster/dist/leaflet.markercluster');

window.L.Place = L.extend({
    search: function (place) {
        var url = 'https://nominatim.openstreetmap.org/search?format=json&q=' + place + ', Belgique&addressdetails=1';
        var result = L.latLng(0, 0);

        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, false);
        xhr.onload = function () {
            var status = xhr.status;
            if (status == 200) {
                var data = JSON.parse(xhr.responseText);
                if(data.length > 0) {
                    result.lat = data[0].lat;
                    result.lng = data[0].lon;
                }else{
                    result = false;
                }
            } else {
                console.log("Leaflet.GeoIP.getPosition failed because its XMLHttpRequest got this response: " + xhr.status);
            }
        };
        xhr.send();

        return result;

    }
})

/**
 * Slider component => https://nightcatsama.github.io/vue-slider-component/example/
 */
import vueSlider from 'vue-slider-component';
Vue.component('vueSlider', vueSlider)

/**
 * Entreprise Demand Form
 */
import ModalEntrepriseDemand from './components/ModalEntrepriseDemand';
Vue.component('entreprise-demand', ModalEntrepriseDemand);
import SvgMinus from './components/SvgMinus';
Vue.component('svg-minus', SvgMinus);
import SvgPlus from './components/SvgPlus';
Vue.component('svg-plus', SvgPlus);
import SvgSpyglass from './components/SvgSpyglass';
Vue.component('svg-spyglass', SvgSpyglass);
